<template>
  <admin>
    <page-header :back="{ to: { name: 'dealer-groups' }, text: trans('Dealer Groups') }">
      <template v-slot:title><span v-text="trans('Edit Dealer Groups')"></span></template>
    </page-header>

    <div class="row">
      <div class="col-lg-9 col-12">
        <Details v-if="details" :details="details" />
      </div>
    </div>
  </admin>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Details from "./partials/forms/Details.vue";

export default {
  components: {
    Details,
  },
  computed: {
    ...mapGetters({
      details: "dealerGroups/GET_GROUP",
    }),
  },
  methods: {
    ...mapActions({
      getGroup: "dealerGroups/getGroup",
    }),
  },
  async mounted() {
    await this.getGroup(this.$route.params.id);
  },
};
</script>

<style></style>
